import React from "react";
import styled from "styled-components";
import { H5 } from "../../App.style";
import { useTranslation } from "react-i18next";

const AboutContainer = styled.div`
  margin: 24px 0px;
`;

const About = () => {
  const { t } = useTranslation();
  return (
    <AboutContainer>
      <H5>
        {t("about.text1")}
        {/* <br></br>
        <br></br>
        {t("about.text2")} */}
      </H5>
    </AboutContainer>
  );
};

export default About;

import React from "react";
import {
  LinkIcon,
  DateLine,
  ProjectContainer,
  ProjectIcon,
  ProjectTitleContainer,
  ProjectsContainer,
  TechnologiesLine,
  TitleIconWrapper,
} from "./Projects.style";
import { H3, H5, H6 } from "../../App.style";
import { DescriptionLine } from "../career_path/CareerPath.style";
import pcIcon from "../../assets/projects/pc.png";
import kayduffbloomIcon from "../../assets/projects/kayduffbloom.png";
import speedtypingIcon from "../../assets/projects/speed-typing.png";
import adoptlyIcon from "../../assets/projects/adoptly.svg";
import mcarIcon from "../../assets/projects/mcar.svg";
import mysticerIcon from "../../assets/projects/mysticer.png";
import linkIcon from "../../assets/link.png";
import { useTranslation } from "react-i18next";

interface Project {
  date: string;
  logo: string;
  title: string;
  link: string;
  description: string;
  technologies: string[];
  paused?: boolean;
}

const Projects = () => {
  const { t } = useTranslation();

  const data: Project[] = [
    {
      date: t("projects.adoptly.date"),
      description: t("projects.adoptly.description"),
      logo: adoptlyIcon,
      title: "Adoptly",
      link: "https://adoptly.pl/",
      technologies: [
        "react",
        "ts",
        "redux",
        "styled-components",
        "django",
        "drf",
      ],
      paused: true,
    },
    {
      date: t("projects.mysticer.date"),
      description: t("projects.mysticer.description"),
      logo: mysticerIcon,
      title: "Mysticer",
      link: "https://mysticer.com/",
      technologies: [
        "react",
        "ts",
        "redux",
        "styled-components",
        "i18next",
        "firestore",
      ],
    },
    {
      date: t("projects.mcar.date"),
      description: t("projects.mcar.description"),
      logo: mcarIcon,
      title: "M-Car Technology",
      link: "https://m-car-technology.pl/",
      technologies: ["react", "ts", "styled-components", "i18next"],
    },
    {
      date: t("projects.pc.date"),
      description: t("projects.pc.description"),
      logo: pcIcon,
      title: "Project: Career",
      link: "https://project-career.web.app/",
      technologies: ["react", "ts", "redux", "styled-components", "firestore"],
    },
    {
      date: t("projects.speedTyping.date"),
      description: t("projects.speedTyping.description"),
      logo: speedtypingIcon,
      title: "Speed Typing Test",
      link: "https://typing-speed-test-48d2d.web.app/",
      technologies: ["react", "ts", "styled-components", "firestore"],
    },
    {
      date: t("projects.kb.date"),
      description: t("projects.kb.description"),
      logo: kayduffbloomIcon,
      title: "Kayduff Bloom",
      link: "https://www.kayduffbloom.com/",
      technologies: ["react", "ts", "styled-components", "firestore"],
    },
  ];
  return (
    <ProjectsContainer>
      {data.map((project, index) => {
        const handleClick = () => {
          if (!project.paused) window.open(project.link, "_blank");
        };
        return (
          <ProjectContainer key={`project-${index}`} data-aos="fade-up">
            <DateLine>
              <H6 style={{ fontWeight: 200 }}>{project.date}</H6>
            </DateLine>
            <ProjectTitleContainer
              onClick={handleClick}
              $noCursor={project.paused}
            >
              <ProjectIcon src={project.logo} alt={""} />
              <TitleIconWrapper $noUnderline={project.paused}>
                <H3>{project.title}</H3>
                {!project.paused && <LinkIcon src={linkIcon} />}
              </TitleIconWrapper>
            </ProjectTitleContainer>

            <DescriptionLine>
              <H5>{project.description}</H5>
            </DescriptionLine>
            <TechnologiesLine>
              <H6>{project.technologies.join(" • ")}</H6>
            </TechnologiesLine>
          </ProjectContainer>
        );
      })}
    </ProjectsContainer>
  );
};

export default Projects;

import React from "react";
import {
  CareerPathContainer,
  CompanyLine,
  DateLine,
  DescriptionLine,
  ExperienceContainer,
  TechnologiesLine,
} from "./CareerPath.style";
import { H3, H5, H6 } from "../../App.style";
import { useTranslation } from "react-i18next";

interface Experience {
  date: string;
  companyName: string;
  position: string;
  description?: string;
  technologies?: string[];
}

const CareerPath = () => {
  const { t } = useTranslation();
  const data: Experience[] = [
    {
      date: t("career.lekta.date"),
      companyName: t("career.lekta.name"),
      position: t("career.lekta.position"),
      description: t("career.lekta.description"),
      technologies: ["react", "ts", "styled-components", "redux", "elastic UI"],
    },
    {
      date: t("career.wsb.date"),
      companyName: t("career.wsb.name"),
      position: t("career.wsb.position"),

      technologies: ["js", "html", "css"],
    },
  ];
  return (
    <CareerPathContainer>
      {data.map((item, index) => {
        const isNotFirstItem = index !== 0;
        return (
          <ExperienceContainer
            key={`experience-${index}`}
            data-aos={isNotFirstItem ? "fade-up" : ""}
          >
            <DateLine>
              <H6 style={{ fontWeight: 200 }}>{item.date}</H6>
            </DateLine>
            <CompanyLine style={{ marginBottom: 8 }}>
              <H3>
                {item.position} - {item.companyName}
              </H3>
            </CompanyLine>
            {/* <PositionLine>
              <H4>{item.position}</H4>
            </PositionLine> */}
            {item.description && (
              <DescriptionLine>
                <H5>{item.description}</H5>
              </DescriptionLine>
            )}
            {item.technologies && (
              <TechnologiesLine>
                <H6>{item.technologies.join(" • ")}</H6>
              </TechnologiesLine>
            )}
          </ExperienceContainer>
        );
      })}
    </CareerPathContainer>
  );
};

export default CareerPath;

import React from "react";
import styled, { keyframes } from "styled-components";
import { BACKGROUND_COLOR } from "../App.style";

export const LoadingScreenContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  background: ${BACKGROUND_COLOR};
  top: 0;
  z-index: 20000;
  overflow-y: hidden;
`;

const pulse = keyframes`
  to {
    transform: scale(0.8);
    opacity: 0.5;
  }
`;

const Loader = styled.span`
  color: #fff;
  font-family: Consolas, Menlo, Monaco, monospace;
  font-weight: bold;
  font-size: 78px;
  opacity: 0.8;

  &:before {
    content: "{";
    display: inline-block;
    animation: ${pulse} 0.4s alternate infinite ease-in-out;
  }

  &:after {
    content: "}";
    display: inline-block;
    animation: ${pulse} 0.4s 0.3s alternate infinite ease-in-out;
  }
`;

const Loading = () => {
  return (
    <LoadingScreenContainer>
      <Loader />
    </LoadingScreenContainer>
  );
};

export default Loading;

import styled, { css } from "styled-components";
import { BACKGROUND_COLOR } from "../../App.style";

export const Nav = styled.nav`
  width: 100%;
  height: 80px;
  max-width: 600px;
  display: flex;
  align-items: center;
  background: transparent;
  position: relative; //was fixed
  top: 0px;
  z-index: 1000;
  justify-content: space-between;
`;

export const NavbarLeftContainer = styled.div`
  height: 100%;
  gap: 16px;
  display: flex;

  align-items: center;
`;

export const NavbarRightContainer = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

interface NavItemProps {
  $hideOnMobile?: boolean;
  $isActive: boolean;
}

export const NavItem = styled.a<NavItemProps>`
  position: relative;
  margin-right: 15px;
  cursor: pointer;
  text-decoration: none;
  color: white;

  font-family: "Nunito", sans-serif;
  font-size: 22px;
  font-weight: 300;
  display: inline-flex;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover::before {
    visibility: visible;
    width: 100%;
  }

  ${(props) =>
    props.$isActive &&
    css`
      &::before {
        visibility: visible;
        width: 100%;
      }
    `}

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 640px) {
    display: ${(props) => (props.$hideOnMobile ? "none" : "block")};
    margin: ${(props) => (props.$hideOnMobile ? "" : "8px 0px")};
  }
`;

export const NavbarContactButtonMobile = styled.button`
  border: 1px solid white;
  background: transparent;
  color: white;

  cursor: pointer;
  padding: 0px 15px;
  min-width: 70px;
  height: 35px;

  font-family: "Nunito", sans-serif;
  font-size: 22px;
  font-weight: 400;

  border-radius: 4px;

  background: #1e1e1e;

  @media (min-width: 641px) {
    display: none;
  }
`;

export const OpenLinksButton = styled.button`
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;

  border-radius: 8px;

  background: transparent;
  box-sizing: border-box;
  border: none;

  background: #1e1e1e;

  margin-right: 30px;

  border: 1px solid white;

  @media (min-width: 641px) {
    display: none;
  }
`;

export const Bar = styled.div`
  width: 25px;
  height: 3px;

  background-color: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  transition: 0.4s ease;

  &:not(:first-child) {
    margin-top: 5px;
  }
`;

export const MenuLabel = styled.label<{ $isChecked: boolean }>`
  position: absolute;
  left: 19px;
  top: 22px;
  transform: ${({ $isChecked }) =>
    $isChecked
      ? "translate(-50%, -50%) rotateY(180deg)"
      : "translate(-50%, -50%)"};
  cursor: pointer;
  transition: 0.5s ease;
  height: 25px;

  &:hover ${Bar} {
    box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.3);
  }

  ${Bar} {
    ${({ $isChecked }) =>
      $isChecked &&
      `
        transform: rotateY(180deg) rotateX(360deg);
  
        &:first-child {
          transform: rotate(-45deg);
          margin-top: 7px;
        }
  
        &:nth-child(2) {
          opacity: 0;
        }
  
        &:nth-child(3) {
          margin-top: -11px;
          transform: rotate(45deg);
        }
      `}
  }
`;

export const NavbarExtendedContainer = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: ${BACKGROUND_COLOR};
  position: fixed;
  top: 0;
  margin-top: 80px;
  left: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 250px; // Set a width for the sliding menu
  transform: translateX(100%); // Start hidden to the right
  transition: transform 0.3s ease-out; // Smooth transition for sliding in
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5); // Subtle shadow for depth
  gap: 20px;
  padding-bottom: 20px;

  @media (min-width: 1000px) {
    width: 0; // Ensure it doesn't show on larger screens
    display: none; // Hide it on larger screens
  }

  // Apply the transition effect when the isOpen state changes
  transform: ${(props) =>
    props.$isOpen ? "translateX(0)" : "translateX(-100%)"};
`;

//test

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 20px;
  left: 20px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
`;

export const HamburgerLines = styled.div`
  display: block;
  height: 26px;
  width: 32px;
  position: absolute;
  top: 17px;
  left: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Line = styled.span`
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
`;

export const Line1 = styled(Line)`
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
`;

export const Line2 = styled(Line)`
  transition: transform 0.2s ease-in-out;
`;

export const Line3 = styled(Line)`
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
`;

// export const NavbarContactButtonDesktop = styled.button`
//   border: 1px solid white;
//   background: transparent;
//   color: white;

//   cursor: pointer;
//   padding: 0px 15px;
//   min-width: 70px;
//   height: 35px;

//   font-family: "Nunito", sans-serif;
//   font-size: 22px;
//   font-weight: 400;

//   border-radius: 4px;

//   transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
//     border-color 0.3s ease-in-out;

//   &:hover {
//     background: white;
//     color: black;
//     border: 1px solid black;
//   }

//   @media (max-width: 640px) {
//     display: none;
//   }
// `;

import { translationsEn } from "./en";

export const translationsPl: typeof translationsEn = {
  navbar: {
    about: "O mnie",
    career: "Kariera",
    projects: "Projekty",
    contact: "Kontakt",
  },

  about: {
    text1:
      "Cześć, jestem Kuba, front-end developer z dwuletnim doświadczeniem w pracy z Reactem. Obecnie jestem również studentem trzeciego roku informatyki. Cenię sobie ciągły rozwój osobisty, nieustannie uczę się nowych rzeczy i rozwijam kolejne projekty.",
    text2:
      "Pracuję z technologiami, takimi jak React, TypeScript, Styled-Components, Redux, Firestore i Firebase, tworząc nowoczesne aplikacje internetowe z naciskiem na interfejsy użytkownika.",
  },

  career: {
    lekta: {
      date: "wrz 2022 - teraz",
      name: "Lekta Tech",
      position: "Front-end Developer",
      description:
        "Jestem częścią ośmioosobowego zespołu, który obecnie zajmuje się tworzeniem strony internetowej z graficznym interfejsem użytkownika umożliwiającym dostosowywanie bota głosowego zgodnie z wymaganiami użytkownika. W zespole odpowiadam za samodzielne prowadzenie procesu tworzenia front-endu strony internetowej od początku do końca.",
    },
    wsb: {
      date: "paź 2021 - teraz",
      name: "Uniwersytet WSB",
      position: "Inżynieria Oprogramowania",
      descrpition: "",
    },
    londzin: {
      date: "wrz 2014 - kwi 2019",
      name: "Technikum",
      position: "Student Informatyki",
      descrpition: "",
    },
  },

  projects: {
    adoptly: {
      date: "w trakcie",
      description:
        "Strona umożliwiająca dopasowanie zwierząt domowych ze schronisk i fundacji do potencjalnych właścicieli, na podstawie formularza osobowości.",
    },
    mysticer: {
      date: "2024",
      description:
        "Innowacyjny wróżbita AI, oferujący różne rodzaje przepowiedni.",
    },
    mcar: {
      date: "2023",
      description:
        "Spersonalizowana strona internetowa dopasowana do wymagań klienta, stworzona na potrzeby warsztatu samochodowego specjalizującego się w naprawach samochodów marki BMW",
    },
    pc: {
      date: "2023",
      description:
        "Test osobowości MBTI, przedstawiający kariery odpowiednie dla uzyskanego typu osobowości.",
    },
    speedTyping: {
      date: "2023",
      description:
        "Aplikacja internetowa, zoptymalizowana zarówno dla urządzeń stacjonarnych, jak i mobilnych, oferująca test szybkości pisania oraz kompleksowy system rankingowy użytkowników, wyróżniający najlepsze wyniki.",
    },
    kb: {
      date: "2023",
      description:
        "Strona internetowa dostosowana do unikalnych potrzeb i artystycznej wizji wschodzącego muzyka i artysty.",
    },
  },
};

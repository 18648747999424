import styled, { css } from "styled-components";
import { H3 } from "../../App.style";

export const ProjectsContainer = styled.div`
  width: 100%;
`;

export const ProjectContainer = styled.div`
  margin: 24px 0px;

  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
`;

export const DateLine = styled.div``;

export const LinkIcon = styled.img`
  margin-left: 15px;
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease-in-out;
`;

export const H3Underlined = styled(H3)`
  text-decoration: none;
`;

export const TitleIconWrapper = styled.div<{ $noUnderline?: boolean }>`
  display: inline-flex;
  align-items: center;
  position: relative;

  ${(props) =>
    !props.$noUnderline &&
    css`
      ::before {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: currentColor;
        visibility: hidden;
        transition: all 0.5s ease-in-out;
      }

      &:hover {
        ::before {
          width: 100%;
          visibility: visible;
        }
      }
    `}

  &:hover {
    ${(props) =>
      !props.$noUnderline &&
      css`
        ${LinkIcon} {
          transform: scale(1.1);
        }
      `}
  }

  @media (max-width: 768px) {
    ::before {
      display: none;
    }

    &:hover {
      ${LinkIcon} {
        transform: none;
      }
    }
  }
`;

export const ProjectTitleContainer = styled.div<{ $noCursor?: boolean }>`
  margin: 8px 0px;
  display: flex;
  align-items: flex-end;
  align-items: center;

  gap: 16px;

  cursor: ${(props) => (props.$noCursor ? "" : "pointer")};
`;

export const ProjectIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export const TechnologiesLine = styled.div`
  color: grey;
`;

export const translationsEn = {
  navbar: {
    about: "About",
    career: "Career",
    projects: "Projects",
    contact: "Contact",
  },

  about: {
    text1: `Hi, I'm Kuba, a front-end developer with two years of experience working with React. I'm also currently a third-year computer science student. I value continuous personal development, constantly learning new things and working on new projects.`,
    text2: `I specialize in working with React, TypeScript, Styled-Components, Redux, Firestore, and Firebase, creating modern web applications with a focus on user interfaces.`,
  },

  career: {
    lekta: {
      date: "sept 2022 - now",
      name: "Lekta tech",
      position: "Front-end Developer",
      description:
        "I am part of an eight-member team currently engaged in the development of a website featuring a graphical user interface for customizing a voice bot according to user requirements. Within the team, my responsibility is to independently handle the end-to-end creation of the frontend of the website.",
    },
    wsb: {
      date: "oct 2021 - now",
      name: "WSB University",
      position: "Software Engineering",
      descrpition: "",
    },
    londzin: {
      date: "sept 2014 - apr 2019",
      name: "Technical High School",
      position: "Computer Science Student",
      descrpition: "",
    },
  },

  projects: {
    adoptly: {
      date: "ongoing",
      description:
        "A website that matches pets from shelters and foundations with owners based on a personality questionnaire.",
    },
    mysticer: {
      date: "2024",
      description:
        "An innovative AI fortune-teller offering various types of predictions.",
    },
    mcar: {
      date: "2023",
      description:
        "A personalized website tailored to the client's needs, created for a car workshop specializing in BMW car repairs.",
    },
    pc: {
      date: "2023",
      description:
        "A website allowing users to take an MBTI personality test and presenting careers suitable for the obtained personality type.",
    },
    speedTyping: {
      date: "2023",
      description: `Web application, optimized for both desktop and mobile devices, that features speed texting test and a comprehensive user ranking system, highlighting the top performers' results.`,
    },
    kb: {
      date: "2023",
      description:
        "Customized website designed to the unique needs and artistic vision of an emerging musician and artist.",
    },
  },
};

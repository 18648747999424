import styled, { css, keyframes } from "styled-components";

export const HeaderContainer = styled.div`
  /* margin-top: 80px;  */
  margin-bottom: 64px;
  width: 100%;
  display: flex;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

export const LeftContainer = styled.div`
  width: 30%;
`;

const jumpAndFall = keyframes`
  0% {
    transform: translateY(0) rotateY(0deg);
  }
  15% {
    transform: translateY(-40px) rotateY(0deg);
  }
  30% {
    transform: translateY(0) rotateY(360deg);
  }
  100% {
    transform: translateY(230vh) rotateY(360deg);
    visibility: hidden;
  }
`;

const shake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(6px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 4px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

interface AvatarProps {
  $jump?: boolean;
  $isShaking: boolean;
}

export const Avatar = styled.img<AvatarProps>`
  width: 80%;
  border-radius: 50%;
  transition: transform 0.5s ease-in-out;

  position: relative;

  cursor: pointer;

  transform: ${(props) =>
    props.$jump ? "translateY(-100vh) rotateY(360deg)" : "none"};
  animation: ${(props) =>
    props.$jump
      ? css`
          ${jumpAndFall} 2s linear
        `
      : "none"};
  animation-fill-mode: forwards;

  &:active {
    animation: ${(props) =>
      props.$isShaking
        ? css`
            ${shake} 0.5s cubic-bezier(.36,.07,.19,.97) both
          `
        : "none"};
  }
`;

export const RightContainer = styled.div`
  width: 70%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`;

import styled from "styled-components";

export const CareerPathContainer = styled.div`
  width: 100%;
`;

export const ExperienceContainer = styled.div`
  margin: 24px 0px;

  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 40px;
`;

export const DateLine = styled.div``;

export const CompanyLine = styled.div`
  display: flex;
  align-items: center;
`;

export const PositionLine = styled.div``;

export const DescriptionLine = styled.div``;

export const TechnologiesLine = styled.div`
  color: grey;
`;

//components/Section.tsx
import React, { forwardRef } from "react";
import styled from "styled-components";
import { H2 } from "../App.style";

const Wrapper = styled.section`
  width: 100%;
  margin-bottom: 40px;
  color: white;
`;

interface SectionProps {
  id: string;
  title: string;
  children?: React.ReactNode; // to include children
}

export const Section = forwardRef<HTMLElement, SectionProps>(
  ({ id, title, children }, ref) => {
    return (
      <Wrapper id={id} ref={ref}>
        <div data-aos="fade-up">
          <H2>{title}</H2>
        </div>
        {children}
      </Wrapper>
    );
  }
);

export default Section;

import { between } from "polished";
import styled from "styled-components";

export const BACKGROUND_COLOR = "#0f0f0f";

export const H1 = styled.h1`
  font-family: "Roboto", sans-serif;

  font-size: ${between("29px", "50px", "320px", "640px")};
  font-weight: 700;

  @media (min-width: 640px) {
    font-size: 50px;
  }
`;

export const H2 = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: ${between("20px", "30px", "320px", "640px")};
  font-weight: 500;
  letter-spacing: 0.5px;

  @media (min-width: 640px) {
    font-size: 27px;
  }
`;

export const H3 = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: ${between("18px", "22px", "320px", "640px")};
  font-weight: 400;

  @media (min-width: 640px) {
    font-size: 22px;
  }
`;

export const H4 = styled.p`
  font-family: "Nunito", sans-serif;
  font-size: ${between("17px", "22px", "320px", "640px")};
  font-weight: 300;

  @media (min-width: 640px) {
    font-size: 22px;
  }
`;

export const H5 = styled.p`
  font-family: "Nunito", sans-serif;
  font-size: ${between("16px", "18px", "320px", "640px")};
  font-weight: 200;

  @media (min-width: 640px) {
    font-size: 18px;
  }
`;

export const H6 = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
`;

export const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;

  display: flex;

  padding-bottom: 64px;

  justify-content: center;

  background: ${BACKGROUND_COLOR};
`;

export const ContentContainer = styled.div`
  width: 90%;
  max-width: 600px;
  height: 100%;

  position: relative;

  display: flex;
  flex-direction: column;

  color: white;
`;

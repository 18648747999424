import React from "react";
import { Nav, NavbarRightContainer } from "./Navbar.style";
import { useTranslation } from "react-i18next";
import plFlag from "../../assets/pl-flag.png";
import enFlag from "../../assets/en-flag.jpeg";
import { H3 } from "../../App.style";

const FakeNavbar = () => {
  const { i18n } = useTranslation();
  return (
    <Nav>
      <div />

      <div
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          gap: 8,
        }}
        onClick={() =>
          i18n.changeLanguage(i18n.language === "pl-PL" ? "en-GB" : "pl-PL")
        }
      >
        <img
          style={{ width: 20, height: 14 }}
          src={i18n.language === "pl-PL" ? plFlag : enFlag}
          alt="language"
        />
        <H3>{i18n.language === "pl-PL" ? "PL" : "EN"}</H3>
      </div>
    </Nav>
  );
};

export default FakeNavbar;

import React, { useEffect, useRef, useState } from "react";
import { ContentContainer, MainContainer } from "./App.style";
import Section from "./components/Section";
import Navbar from "./components/navbar/Navbar";
import Header from "./components/header/Header";
import About from "./components/about/About";
import CareerPath from "./components/career_path/CareerPath";
import Projects from "./components/projects/Projects";
import Contact from "./components/contact/Contact";
import Loading from "./components/Loading";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./translations/i18n";
import AOS from "aos";
import "aos/dist/aos.css";
import FakeNavbar from "./components/navbar/FakeNavbar";

function App() {
  const { t } = useTranslation();
  const aboutRef = useRef<HTMLElement>(null);
  const careerRef = useRef<HTMLElement>(null);
  const projectsRef = useRef<HTMLElement>(null);
  const contactRef = useRef<HTMLElement>(null);

  const [activeSection, setActiveSection] = useState("about");

  useEffect(() => {
    const aboutElement = aboutRef.current;
    const careerElement = careerRef.current;
    const projectsElement = projectsRef.current;
    const contactElement = contactRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio) {
            setActiveSection(entry.target.id);
          }
        });
      },

      //how many % from top to fire
      { threshold: 0.3 }
    );

    if (aboutElement) observer.observe(aboutElement);
    if (careerElement) observer.observe(careerElement);
    if (projectsElement) observer.observe(projectsElement);
    if (contactElement) observer.observe(contactElement);

    return () => {
      if (aboutElement) observer.unobserve(aboutElement);
      if (careerElement) observer.unobserve(careerElement);
      if (projectsElement) observer.unobserve(projectsElement);
      if (contactElement) observer.observe(contactElement);
    };
  }, []);

  const [loading, setLoading] = useState<boolean>(true);

  const handleLoad = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  //scroll animation
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <MainContainer>
        <ContentContainer>
          {/* <Navbar activeSection={activeSection} /> */}
          <FakeNavbar />

          <Header handleLoad={handleLoad} loading={loading} />

          <Section
            id="about"
            title={t("navbar.about")}
            ref={aboutRef}
            data-aos="fade-up"
          >
            <About />
          </Section>

          <Section id="career" title={t("navbar.career")} ref={careerRef}>
            <CareerPath />
          </Section>

          <Section id="projects" title={t("navbar.projects")} ref={projectsRef}>
            <Projects />
          </Section>

          <Section id="contact" title={t("navbar.contact")} ref={contactRef}>
            <Contact />
          </Section>
        </ContentContainer>

        {loading && <Loading />}
      </MainContainer>
    </I18nextProvider>
  );
}

export default App;

import styled from "styled-components";

export const ContactContainer = styled.div`
  width: 100%;
  margin: 24px 0px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContactLine = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
`;

export const ContactTitle = styled.div`
  width: 120px;
`;

export const LinkIcon = styled.img`
  margin-left: 15px;
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease-in-out;
`;

export const ContactContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  cursor: pointer;

  /* Adding a bottom pseudo-element for the underline effect */
  ::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white; // change this to match your link color
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    /* Changing the width of the pseudo-element to 100% on hover */
    ::before {
      width: 100%;
      visibility: visible;
    }

    ${LinkIcon} {
      transform: scale(1.1);
    }
  }

  @media (max-width: 768px) {
    ::before {
      display: none;
    }

    &:hover {
      ${LinkIcon} {
        transform: none;
      }
    }
  }
`;

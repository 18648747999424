import React, { useEffect, useState } from "react";
import {
  Avatar,
  HeaderContainer,
  LeftContainer,
  RightContainer,
} from "./Header.style";
import avatar from "../../assets/avatar.png";
import { TypeAnimation } from "react-type-animation";
import { H1 } from "../../App.style";

interface HeaderProps {
  handleLoad: () => void;
  loading: boolean;
}

const Header: React.FC<HeaderProps> = ({ handleLoad, loading }) => {
  const [showAnimation, setShowAnimation] = useState<boolean>(false);

  useEffect(() => {
    if (!loading) {
      setShowAnimation(true);
    }
  }, [loading]);

  const [avatarClick, setAvatarClick] = useState<number>(0);
  const [isJumping, setIsJumping] = useState<boolean>(false);

  const handleAvatarClick = () => {
    if (avatarClick < 2) {
      setAvatarClick((prev) => prev + 1);
    } else {
      setIsJumping((prev) => !prev);
      setTimeout(() => {
        setIsJumping(false);
        setAvatarClick(0);
      }, 2000 + 3000);
    }
  };

  const handleClickAvatarSpace = () => {
    if (isJumping) {
      setIsJumping(false);
      setAvatarClick(0);
    }
  };

  return (
    <HeaderContainer>
      <LeftContainer onClick={handleClickAvatarSpace}>
        <Avatar
          src={avatar}
          alt={"my image"}
          onLoad={handleLoad}
          onClick={handleAvatarClick}
          $jump={isJumping}
          $isShaking={true}
        />
      </LeftContainer>
      <RightContainer>
        <H1>
          {showAnimation && (
            <TypeAnimation
              sequence={["Jakub Kracla"]}
              wrapper="span"
              cursor={false}
              speed={1}
            />
          )}
        </H1>
      </RightContainer>
    </HeaderContainer>
  );
};

export default Header;

import React from "react";
import {
  ContactContainer,
  ContactContent,
  ContactLine,
  ContactTitle,
} from "./Contact.style";
import { H4, H5 } from "../../App.style";
import linkIcon from "../../assets/link.png";
import { LinkIcon } from "./Contact.style";

interface IContact {
  title: string;
  content: string;
  link: string;
}

const contactData: IContact[] = [
  {
    title: "Mail",
    content: "jakubkracla@gmail.com",
    link: "mailto:jakubkracla@gmail.com",
  },
  {
    title: "GitHub",
    content: "@Kubvvv",
    link: "https://github.com/Kubvvv",
  },
  {
    title: "LinkedIn",
    content: "Jakub Kracla",
    link: "https://www.linkedin.com/in/jakub-kracla-9411a5234/",
  },
  {
    title: "Codewars",
    content: "@Kubvvv",
    link: "https://www.codewars.com/users/Kubvvv",
  },
  // {
  //   title: "Resume",
  //   content: "download",
  //   link: "https://drive.google.com/file/d/1SwyMe7hCsWTmyGyEH0p56fpJsfPozOOt/view?usp=sharing",
  // },
];

const Contact = () => {
  return (
    <ContactContainer data-aos="fade-up">
      {contactData.map((contact, index) => {
        return (
          <ContactLine key={`contact-${index}`}>
            <ContactTitle>
              <H5>{contact.title}</H5>
            </ContactTitle>
            <ContactContent>
              <a
                target="_blank"
                href={contact.link}
                rel="noreferrer"
                style={{
                  color: "white",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: 0,
                }}
              >
                <H4>{contact.content}</H4>
                <LinkIcon src={linkIcon} />
              </a>
            </ContactContent>
          </ContactLine>
        );
      })}
    </ContactContainer>
  );
};

export default Contact;
